import { AfterViewInit, Component, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { GridComponent, PageSettingsModel, RowDataBoundEventArgs, SortService } from '@syncfusion/ej2-angular-grids';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogService } from '@ngneat/dialog';
import { delay, finalize, interval, of, Subscription, switchMap, takeWhile } from 'rxjs';

import {
    BankAccountService,
    ChequeService,
    CommonService,
    ExpenseAccountService,
    LoaderService,
    MenuService,
    SupplierService,
    ToasterService,
} from '@core/services';
import {
    EBatchPrintingType,
    EToasterStatus,
    IBankAccounts,
    IBatchTransation,
    ICheque,
    IECHQAddress,
    IExpenseAccounts,
    IMeta,
    ISupplier,
} from '@core/models';
import { Constant } from '@core/constants';
import { ConfirmationComponent } from '@shared/components';
import { BatchPrintingDialogComponent } from './batch-printing-dialog/batch-printing-dialog.component';

@Component({
    selector: 'app-batch-printing',
    styleUrls: ['./batch-printing.component.scss'],
    templateUrl: './batch-printing.component.html',
    providers: [SortService],
})
export class BatchPrintingComponent implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild('chequeList')
    gridInstance!: GridComponent;
    public bankAccount: string = '';
    public batchStatus: string = '';
    public bankAccounts: IBankAccounts[] = [];
    public pageSettings!: PageSettingsModel;
    public pageSizes: number[] = [10, 25, 50];
    public cheques: IBatchTransation[] | null = [];
    public filteredCheques: IBatchTransation[] | null = [];
    public suppliers: ISupplier[] = [];
    public batchTransactionID: string = '';
    public pdfUrl!: SafeResourceUrl;
    @ViewChild('pdfViewer') pdfViewer!: ElementRef;
    public bankAccountAddress: IECHQAddress | null = null;
    public expenseAccounts: IExpenseAccounts[] = [];
    private dialog: DialogService = inject(DialogService);
    public paymentTypes: { name: string; id: string }[] = [];
    public pollingIsInProgress: boolean = false;
    public isPolling: boolean = false;
    private dataSubscription!: Subscription;
    public fields: { text: string; value: string } = {
        text: 'name',
        value: 'id',
    };
    public batchStatuses: string[] = ['Completed', 'Completed with errors', 'In progress'];
    public EBatchPrintingType = EBatchPrintingType;

    constructor(
        private menuService: MenuService,
        private bankAccountService: BankAccountService,
        private expenseAccountService: ExpenseAccountService,
        private chequeService: ChequeService,
        private supplierService: SupplierService,
        private sanitizer: DomSanitizer,
        private toasterService: ToasterService,
        private constant: Constant,
        private commonService: CommonService,
        private loaderService: LoaderService
    ) {
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

    ngOnInit(): void {
        this.menuService.updateMenuSelection('batch-printing');
        this.getSuppliers();
        this.getBankAccounts();
        this.getPaymentTypes();
    }

    ngAfterViewInit(): void {
        this.pageSettings = { pageCount: 3, pageSize: this.pageSizes[0] };
    }

    ngOnDestroy() {
        this.stopPolling();
    }

    getPaymentTypes = () => {
        this.chequeService.getPaymentTypes().subscribe({
            next: (res: { name: string; id: string }[]) => {
                if (res) {
                    this.paymentTypes = res;
                }
            },
        });
    };

    getCheques = () => {
        this.chequeService.getBatchTransaction('').subscribe({
            next: (res: IBatchTransation[]) => {
                if (res) {
                    this.cheques = res.map((obj) => {
                        const bankAccount = this.bankAccounts.find((item) => item.id === obj.bankId);
                        obj['bankName'] = bankAccount ? `${bankAccount.name}` : '';
                        return obj;
                    });
                    this.filteredCheques = [...this.cheques];
                }
            },
        });
    };

    getExpenseAccounts = () => {
        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                this.expenseAccounts = res;
                this.getCheques();
            },
        });
    };

    getBankAccounts = () => {
        this.bankAccountService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = res;

                this.getExpenseAccounts();
            },
        });
    };

    getSuppliers = () => {
        this.supplierService.getSuppliers('').subscribe({
            next: (res: { content: ISupplier[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.suppliers = res.content;
                }
            },
        });
    };

    startBatchPrinting = (type: EBatchPrintingType, id: string = '') => {
        const dialogRef = this.dialog.open(BatchPrintingDialogComponent, {
            minWidth: '80vw',
            minHeight: '75vh',
            maxWidth: '100%',
            maxHeight: '90vh',
            data: {
                type,
                batchId: id,
            },
        });
        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                const { crudOperation, batchTransactionID } = result;
                if (crudOperation) {
                    this.pollingIsInProgress = true;
                    this.isPolling = true;
                    this.startPolling(batchTransactionID || '');
                }
            }
        });
    };

    public rowSelectionChanged() {
        const selection = this.gridInstance.getSelectedRecords();
        this.gridInstance.aggregateModule.refresh(selection);
    }

    public rowDataBound(args: RowDataBoundEventArgs): void {
        const rowData = args.data as ICheque;

        args.isSelectable = !(rowData?.chequeNumber && rowData?.chequeId);
    }

    getBatchDetailsAndPrint = (event: MouseEvent, id: string) => {
        event.stopPropagation();

        if (id) {
            this.startBatchPrinting(EBatchPrintingType.PREVIEW_BATCH, id);
        }
    };

    cancel = () => {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            minWidth: '35vw',
            maxWidth: '100%',
            data: {
                title: 'Confirm',
                msg: 'Are you sure want to cancel the print selection?',
            },
        });
        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                this.gridInstance.refresh();
            }
        });
    };

    customAggregateFn = (): number => {
        let sum = 0;
        (this.gridInstance.getSelectedRecords() as unknown as ICheque[]).forEach((data) => {
            sum += data['amount'];
        });
        return sum;
    };

    convertToUserTz = (dateStr: string): string | null => {
        return this.commonService.convertToUserTz(dateStr);
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }

    getStatusClass = (status: string) => {
        if (status) {
            return status.toLowerCase().split(' ').join('-');
        }
        return '';
    };

    startPolling = (batchId: string) => {
        this.loaderService.show();
        this.dataSubscription = interval(5000)
            .pipe(
                takeWhile(() => this.pollingIsInProgress),
                switchMap(() =>
                    this.checkStatusAndFetchData(batchId).pipe(
                        finalize(() => {
                            if (!this.pollingIsInProgress) {
                                this.loaderService.hide();
                            }
                        })
                    )
                )
            )
            .subscribe({
                next: () => {},
                error: () => {
                    this.isPolling = false;
                    this.pollingIsInProgress = false;
                },
            });
    };

    stopPolling() {
        if (this.dataSubscription) {
            this.dataSubscription.unsubscribe();
        }
    }

    checkStatusAndFetchData = (batchId: string) => {
        return this.chequeService.getBatchTransaction('').pipe(
            switchMap((res: IBatchTransation[]) => {
                if (res) {
                    this.cheques = [
                        ...res.map((obj) => {
                            const bankAccount = this.bankAccounts.find((item) => item.id === obj.bankId);
                            obj['bankName'] = bankAccount ? `${bankAccount.name}` : '';
                            return obj;
                        }),
                    ];

                    this.filteredCheques = this.cheques?.filter((item) => {
                        const matchesBankAccount = this.bankAccount ? item.bankId === this.bankAccount : true;
                        const matchesBatchStatus = this.batchStatus ? item.status === this.batchStatus : true;
                        return matchesBankAccount && matchesBatchStatus;
                    });
                }

                const selectedBatch = this.cheques?.find((item) => item.id === batchId);
                if (selectedBatch && (selectedBatch.status === 'Completed' || selectedBatch.status === 'Completed with errors')) {
                    this.stopPolling();
                    this.pollingIsInProgress = false;
                } else if (selectedBatch && selectedBatch.status === 'Failed') {
                    this.stopPolling();
                    this.pollingIsInProgress = false;
                    this.isPolling = false;
                    this.loaderService.hide();
                    this.showToast(EToasterStatus.DANGER, this.constant.BATCH_PRINT_MSG.FAILURE);
                }

                // Delay for 5000 milliseconds before the next poll
                return of(null).pipe(delay(5000));
            })
        );
    };

    handleBankAccountChange = (bankAccount: string) => {
        this.filteredCheques =
            this.cheques?.filter((item) => {
                const matchesBankAccount = this.bankAccount ? item.bankId === bankAccount : true;
                const matchesBatchStatus = this.batchStatus ? item.status === this.batchStatus : true;
                return matchesBankAccount && matchesBatchStatus;
            }) || [];
    };

    handleBatchStatusChange = (batchStatus: string) => {
        this.filteredCheques =
            this.cheques?.filter((item) => {
                const matchesBankAccount = this.bankAccount ? item.bankId === this.bankAccount : true;
                const matchesBatchStatus = this.batchStatus ? item.status === batchStatus : true;
                return matchesBankAccount && matchesBatchStatus;
            }) || [];
    };
}
