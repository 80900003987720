import { Component, ElementRef, OnInit, Renderer2, ViewChild } from '@angular/core';
import { GridComponent, PageSettingsModel, RowSelectEventArgs, SortService } from '@syncfusion/ej2-angular-grids';

import { IEmployeeTable, IEmployee, IUserPayload, AuthState, EToasterStatus } from '@core/models';
import { DialogService } from '@ngneat/dialog';
import { EmployeeService, UserService, ToasterService } from '@core/services';
import { UserDialogComponent } from './user-dialog/user-dialog.component';
import { Constant } from '@core/constants';
import { ConfirmationComponent } from '@app/shared/components';

@Component({
    selector: 'app-user',
    templateUrl: './users.component.html',
    styleUrls: ['./users.component.scss'],
    providers: [SortService],
})
export class UserComponent implements OnInit {
    @ViewChild('overviewgrid')
    gridInstance!: GridComponent;
    public pageSizes: number[] = [6, 12, 18];
    public pageSettings!: PageSettingsModel;
    public showPaginationFlag: boolean = true;
    public loading: boolean = false;
    public users: IEmployeeTable[] = [];
    public avatarColors: string[] = [
        '#281555',
        '#00ff00',
        '#ff0000',
        '#ffa500',
        '#bada55',
        '#800080',
        '#bada55',
        '#407294',
        '#40e0d0',
        '#fdb813',
    ];
    public isAdminUser: boolean = false;
    public userId: string = '';

    constructor(
        private dialog: DialogService,
        private employeeService: EmployeeService,
        private userService: UserService,
        private toasterService: ToasterService,
        private constant: Constant,
        private elementRef: ElementRef,
        private renderer: Renderer2
    ) {}

    ngOnInit(): void {
        const role: string = this.userService.userRole;
        this.isAdminUser = role === this.constant.USER_ROLES.ADMIN;
        this.userId = this.userService.userId;
        this.getAllEmployees();
    }

    getAllEmployees = () => {
        this.loading = true;
        const orgId: string = this.userService.organizationId;

        if (orgId) {
            this.employeeService.getAllEmployees(orgId).subscribe({
                next: (data: IEmployee[]) => {
                    this.loading = false;
                    this.users = data.map((v) => {
                        return {
                            id: (v || {}).id || '',
                            name: (v || {}).name || '',
                            email: (v || {}).email || '',
                            employeeName: (v || {}).name ? `${v.name.last || ''}, ${v.name.first || ''} ${v.name.middle || ''}` : '',
                            phone: this.getPhoneNumber(v.phones) || '',
                            title: ((v || {}).details || {}).title || '',
                            role: (v || {}).role || '',
                            status: (v || {}).status || '',
                        };
                    });
                },
                error: () => {
                    this.loading = false;
                    this.users = [];
                },
                complete: () => {
                    setTimeout(() => {
                        const scrollParent = this.elementRef.nativeElement.querySelector('#overviewgrid');
                        const scrollableElement = this.elementRef.nativeElement.querySelector('#overviewgrid .e-gridcontent');

                        if (scrollableElement) {
                            const verticalScrollbarExists = scrollableElement.scrollHeight >= scrollableElement.clientHeight;

                            if (verticalScrollbarExists) {
                                this.renderer.addClass(scrollParent, 'has-scrollbar');
                            }
                        }
                    }, 100);
                },
            });
        }
    };

    rowSelected(args: RowSelectEventArgs) {
        if (!this.isAdminUser) return;

        const id = (args?.data as IEmployeeTable)?.id || '';
        const target = ((args || {}).target || {}).textContent || '';
        if (target.trim() == 'Resend verification email') {
            this.openConfirmDialog(id);
        } else {
            this.handleUserDialog(true, (args.data as unknown as IEmployee).id);
        }
    }

    openConfirmDialog(id: string) {
        const dialogRef = this.dialog.open(ConfirmationComponent, {
            minWidth: '35vw',
            maxWidth: '100%',
            data: {
                title: 'Confirm',
                msg: 'Are you sure, you want to send verification email?',
                confirmText: 'Yes',
                cancelText: 'No',
            },
        });
        dialogRef.afterClosed$.subscribe((result) => {
            if (result) {
                this.resendVerificationEmail(id);
            }
        });
    }

    resendVerificationEmail(empId: string): void {
        this.loading = true;
        const actionsPaylod = {
            id: empId,
        };
        this.employeeService.resendVerificationEmail(actionsPaylod).subscribe(
            (data) => {
                this.loading = false;
                this.toasterService.showToast(EToasterStatus.SUCCESS, this.constant.RESEND_VERIFICATION_EMAIL_MSG.SUCCESS);
                this.getAllEmployees();
            },
            (err) => {
                this.loading = false;
                this.toasterService.showToast(EToasterStatus.DANGER, this.constant.RESEND_VERIFICATION_EMAIL_MSG.FAILURE);
            }
        );
    }

    handleUserDialog = (isEdit: boolean, userID = '') => {
        const dialogRef = this.dialog.open(UserDialogComponent, {
            minWidth: '65vw',
            maxWidth: '100%',
            maxHeight: '80vh',
            data: {
                isEdit,
                userID,
            },
        });
        dialogRef.afterClosed$.subscribe((result) => {
            if (result && typeof result === 'object') {
                const { status, payload }: { status: boolean; payload: IUserPayload } = result;
                const userStr: string | null = localStorage.getItem('auth');
                const userInfo: AuthState = userStr ? JSON.parse(userStr) : null;
                const userEmailFromStore: string = userInfo?.user?.email || '';

                if (userEmailFromStore === payload?.email) {
                    const payloadUserNameObj: { first: string; last: string; middle: string } | null = payload?.name || null;

                    if (userInfo && userInfo.user && userInfo.user.profile) {
                        userInfo.user.profile.name = payloadUserNameObj;
                        localStorage.setItem('auth', JSON.stringify(userInfo));
                        this.userService.updateUserNameChangeStatus(true);
                    }
                }

                if (status) {
                    this.getAllEmployees();
                }
            } else if (result && typeof result === 'boolean') {
                this.getAllEmployees();
            }
        });
    };

    getPhoneNumber = (phones: { number: string; extension: string }[]) => {
        if (!phones || phones.length === 0) return 'N/A';

        const { number, extension } = phones[0];

        if (!number || !extension) return 'N/A';
        if (number && extension) return `${extension} ${number}`;

        return number || extension;
    };

    getColor = (fullname: { first: string; last: string; middle: string }) => {
        const fName: string = fullname.first;
        let h = 0;

        for (let i = 0; i < fName.length; i++) {
            h = (h * 31 + fName.charCodeAt(i)) % 10;
        }

        return this.avatarColors[h];
    };

    getInitialName(name: { first: string; last: string; middle: string }): string {
        return name.first.charAt(0).toUpperCase() + name.last.charAt(0).toUpperCase();
    }
}
