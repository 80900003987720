<div class="batch-printing-dialog">
    <div class="batch-printing-dialog__title">
        {{ isPreviewBatch() ? 'Batch Details' : 'New batch request' }}
    </div>

    <div class="batch-printing-dialog__body">
        <form id="bankDetailsForm" [formGroup]="bankDetailsForm" class="bank-details form">
            <div class="first-row">
                <div class="form__group required">
                    <ejs-dropdownlist
                        id="bankAccount"
                        name="bankAccount"
                        [(ngModel)]="bankAccount"
                        (change)="changeBankAccount($event)"
                        formControlName="bankAccount"
                        [dataSource]="bankAccounts"
                        placeholder="Bank Details"
                        floatLabelType="Always"
                        [fields]="fields"
                    />

                    <ng-container *ngIf="bankDetailsFormF['bankAccount'].touched && bankDetailsFormF['bankAccount'].errors">
                        <p class="caption status-danger" *ngIf="bankDetailsFormF['bankAccount'].errors['required']">
                            Bank account is required.
                        </p>
                    </ng-container>
                </div>

                <div class="form__group required">
                    <ejs-numerictextbox
                        format="###.##"
                        placeholder="Cheque Number"
                        floatLabelType="Always"
                        formControlName="chequeNumber"
                        (change)="handleChequeNumber($event)"
                    />

                    <ng-container *ngIf="bankDetailsFormF['chequeNumber'].touched && bankDetailsFormF['chequeNumber'].errors">
                        <p class="caption status-danger" *ngIf="bankDetailsFormF['chequeNumber'].errors['required']">
                            Cheque number is required.
                        </p>
                    </ng-container>
                </div>

                <div class="form__group required">
                    <ejs-textbox
                        placeholder="Enter name"
                        floatLabelType="Always"
                        formControlName="name"
                        (change)="handleNameChange($event)"
                    />

                    <ng-container *ngIf="bankDetailsFormF['name'].touched && bankDetailsFormF['name'].errors">
                        <p class="caption status-danger" *ngIf="bankDetailsFormF['name'].errors['required']">Name is required.</p>
                    </ng-container>
                </div>
            </div>

            <div class="second-row">
                <div class="form__group--address address" *ngIf="bankAccountAddress">
                    <ng-container *ngIf="bankAccountAddress; else addressEmpty">
                        <div class="address__text">
                            {{ bankAccountAddress.firstLine }}
                        </div>

                        <div class="address__text">
                            <span *ngIf="bankAccountAddress.lastLine">{{ bankAccountAddress.lastLine }}, &nbsp;</span>
                            {{ bankAccountAddress.zipCode }}
                        </div>

                        <div class="address__text">
                            <span *ngIf="bankAccountAddress.city">{{ bankAccountAddress.city }}, &nbsp;</span>
                            {{ bankAccountAddress.state }}
                        </div>

                        <div class="address__text">
                            {{ bankAccountAddress.country }}
                        </div>
                    </ng-container>

                    <ng-template #addressEmpty>-</ng-template>
                </div>

                <div class="batch-printing-dialog__banner" *ngIf="isBatchModified">
                    <div class="batch-printing-dialog__banner--msg">
                        You have made changes to this Batch. When you click "Submit," a new batch request will be created, utilizing the
                        starting cheque number and generating a new document for you to print. If you don't want to make any changes, click
                        "Cancel".
                    </div>
                </div>
            </div>
        </form>

        <ejs-grid
            [dataSource]="cheques"
            #chequeList
            id="chequeList"
            class="batch-printing-dialog__grid"
            rowHeight="45"
            [allowPaging]="true"
            [allowSorting]="true"
            [pageSettings]="pageSettings"
            (rowSelected)="rowSelectionChanged($event)"
            (rowDeselected)="rowSelectionChanged($event)"
            (rowDataBound)="rowDataBound($event)"
            [selectionSettings]="{ checkboxOnly: true }"
        >
            <e-columns>
                <e-column type="checkbox" width="50" headerText="Print?"></e-column>
                <e-column field="supplierCompanyName" headerText="Supplier Name" />
                <e-column field="chequeNumber" headerText="Cheque Number">
                    <ng-template #template let-data>
                        <a
                            href="javascript:void(0)"
                            [ngClass]="['batch-printing__link', data.chequeId ? '' : 'disabled']"
                            (click)="handlePrintCheque($event, data.id)"
                        >
                            <span class="batch-printing__link--cheque">{{ data.chequeId ? data.chequeNumber : '' }}</span>

                            <ejs-tooltip
                                *ngIf="data.isModified"
                                id="tooltip"
                                content="This cheque has been updated after the batch was created. The Batch needs to be submitted again to print this cheque as part of this Batch"
                            >
                                <img class="batch-printing__link--tooltip" src="assets/images/question-icon.png" alt="Help icon" />
                            </ejs-tooltip>
                        </a>
                    </ng-template>
                </e-column>
                <e-column field="transactionDate" headerText="Transaction Date">
                    <ng-template #template let-data>
                        {{ convertToUserTz(data.transactionDate) ? convertToUserTz(data.transactionDate) : '' }}
                    </ng-template>
                </e-column>
                <e-column field="amount" headerText="Amount" format="C2" textAlign="right" />
                <e-column field="paymentTypeName" headerText="Payment Type" />
                <e-column field="memo" headerText="Memo" />
            </e-columns>

            <e-aggregates>
                <e-aggregate>
                    <e-columns>
                        <e-column field="amount" format="c2" type="Custom" [customAggregate]="customAggregateFn">
                            <ng-template #footerTemplate let-data> Total Amount: {{ data.Custom }} </ng-template>
                        </e-column>
                    </e-columns>
                </e-aggregate>
            </e-aggregates>
        </ejs-grid>
    </div>

    <div class="batch-printing-dialog__action">
        <button
            *ngIf="ref?.data?.type === EBatchPrintingType.PREVIEW_BATCH && !isBatchModified"
            [ngClass]="['e-btn e-outline', isPrintDisabled() ? '' : 'e-success']"
            id="cancelBtn"
            content="Start Batch Printing"
            (click)="getPdfFile(initialBatchData?.file || '')"
            [disabled]="isPrintDisabled()"
        >
            Print
        </button>

        <button
            *ngIf="
                ref?.data?.type === EBatchPrintingType.CREATE_BATCH ||
                (ref?.data?.type === EBatchPrintingType.PREVIEW_BATCH && isBatchModified)
            "
            [ngClass]="['e-btn e-outline', isPrintDisabled() ? '' : 'e-success']"
            id="cancelBtn"
            content="Start Batch Printing"
            (click)="startBatchPrinting()"
            [disabled]="isPrintDisabled()"
        >
            Submit
        </button>

        <button [ngClass]="['e-btn e-outline e-danger']" id="cancelBtn" content="Cancel" (click)="cancel()">Cancel</button>
    </div>

    <iframe #pdfViewer [src]="pdfUrl" width="100%" height="600" class="pdf-preview"></iframe>
</div>
