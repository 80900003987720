import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { DialogRef, DialogService } from '@ngneat/dialog';
import {
    ColumnModel,
    EditService,
    GridComponent,
    PageService,
    PageSettingsModel,
    RowDataBoundEventArgs,
    RowSelectEventArgs,
    SortService,
} from '@syncfusion/ej2-angular-grids';
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';

import {
    EToasterStatus,
    IBankAccounts,
    IBatchPrintingPayload,
    ICheque,
    IECHQAddress,
    IExpenseAccounts,
    IMeta,
    ISupplier,
    IBatchPrintingDlgData,
    EBatchPrintingType,
    IBatchTransationDetail,
    IBatchTransationCheque,
} from '@core/models';
import {
    BankAccountService,
    BatchPrintingService,
    ChequeService,
    CommonService,
    ExpenseAccountService,
    LoaderService,
    SupplierService,
    ToasterService,
} from '@core/services';
import { ChangedEventArgs, ChangeEventArgs } from '@syncfusion/ej2-inputs';
import { ConfirmationComponent } from '@app/shared/components';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
    selector: 'app-batch-printing-dialog',
    templateUrl: './batch-printing-dialog.component.html',
    styleUrls: ['./batch-printing-dialog.component.scss'],
    providers: [EditService, PageService, SortService],
})
export class BatchPrintingDialogComponent implements OnInit, AfterViewInit {
    public ref: DialogRef<IBatchPrintingDlgData, { crudOperation: boolean; batchTransactionID?: string }> = inject(DialogRef);
    @ViewChild('chequeList')
    gridInstance!: GridComponent;
    public bankAccount = '';
    public bankAccounts: IBankAccounts[] = [];
    public isValidBankDetails: boolean = false;
    public pageSettings!: PageSettingsModel;
    public pageSizes: number[] = [10, 25, 50];
    public cheques: ICheque[] = [];
    public suppliers: ISupplier[] = [];
    public batchTransactionID: string = '';
    public bankAccountAddress: IECHQAddress | null = null;
    public expenseAccounts: IExpenseAccounts[] = [];
    public paymentTypes: { name: string; id: string }[] = [];
    public fields: { text: string; value: string } = {
        text: 'name',
        value: 'id',
    };
    public bankDetailsForm: FormGroup;
    public EBatchPrintingType = EBatchPrintingType;
    public isBatchModified: boolean = false;
    public initialBatchData: IBatchTransationDetail | null = null;
    private dialog: DialogService = inject(DialogService);
    public pdfUrl!: SafeResourceUrl;
    public chequeSequence: number = 0;
    @ViewChild('pdfViewer') pdfViewer!: ElementRef;

    constructor(
        private toasterService: ToasterService,
        private bankAccountService: BankAccountService,
        private expenseAccountService: ExpenseAccountService,
        private chequeService: ChequeService,
        private supplierService: SupplierService,
        private batchPrintService: BatchPrintingService,
        private commonService: CommonService,
        private fb: FormBuilder,
        private sanitizer: DomSanitizer,
        private loaderService: LoaderService
    ) {
        this.bankDetailsForm = this.fb.group({
            bankAccount: ['', [Validators.required]],
            chequeNumber: ['', [Validators.required]],
            name: [this.getFormattedBatchName(), [Validators.required]],
        });
    }

    ngOnInit(): void {
        this.getSuppliers();
        this.getBankAccounts();
        this.getPaymentTypes();
    }

    ngAfterViewInit(): void {
        this.pageSettings = { pageCount: 3, pageSize: this.pageSizes[0] };
    }

    get bankDetailsFormF(): { [key: string]: AbstractControl } {
        return this.bankDetailsForm.controls;
    }

    get formType(): EBatchPrintingType {
        return this.ref.data.type;
    }

    isPreviewBatch = (): boolean => {
        return this.formType === EBatchPrintingType.PREVIEW_BATCH;
    };

    cancel = () => {
        this.ref.close({ crudOperation: false });
    };

    getBatchDetailsAndPrint = (id: string) => {
        if (id) {
            this.batchPrintService.getBatchTransactionByID(id).subscribe({
                next: (res: IBatchTransationDetail) => {
                    if (res) {
                        this.initialBatchData = res;
                        const { bankId, name, cheques, isModified } = res;
                        const selectedBank = this.bankAccounts.find((bank) => bank.id === bankId);
                        this.cheques = cheques
                            .sort((a: IBatchTransationCheque, b: IBatchTransationCheque) => a.chequeNumber - b.chequeNumber)
                            .map((obj: any) => {
                                const supplier = this.suppliers.find((item) => item.id === obj.transactionDetails?.supplierId);
                                obj['chequeId'] = obj.transactionDetails?.id;
                                obj['transactionDate'] = obj.transactionDetails?.transactionDate || '';
                                obj['amount'] = obj.transactionDetails?.amount || 0;
                                obj['memo'] = obj.transactionDetails?.memo || '';
                                obj['supplierName'] = supplier ? `${supplier.name?.first}, ${supplier.name?.last}` : '';
                                obj['paymentTypeName'] =
                                    this.paymentTypes.find((item) => item.id === obj.transactionDetails?.paymentType)?.name || '';
                                obj['supplierCompanyName'] = supplier?.companyName || '';
                                obj['isModified'] = obj.isModified || false;
                                return obj as unknown as ICheque;
                            });

                        this.isBatchModified = isModified || this.cheques.some((item) => item['isModified']);
                        const [cheque] = this.cheques || [];
                        this.chequeSequence = +(cheque?.chequeNumber || '');
                        this.bankDetailsForm.patchValue({
                            bankAccount: selectedBank?.id,
                            name: name,
                            chequeNumber: cheque?.chequeNumber,
                        });

                        this.getBankAccountByID(selectedBank?.id || '', true); // To update the bank details

                        ((this.gridInstance as GridComponent).columns[4] as ColumnModel).customAttributes = { class: 'currency-column' };
                        (((this.gridInstance as GridComponent).aggregates[0].columns || [])[0] as ColumnModel).customAttributes = {
                            class: 'currency-column',
                        };
                        setTimeout(() => {
                            (this.gridInstance as GridComponent).selectRowsByRange(0, this.cheques.length - 1);
                        }, 100);
                    }
                },
            });
        }
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }

    isPrintDisabled = (): boolean => {
        return this.gridInstance?.getSelectedRecords().length === 0 || !this.bankDetailsForm.valid;
    };

    customAggregateFn = (): number => {
        let sum = 0;
        (this.gridInstance.getSelectedRecords() as unknown as ICheque[]).forEach((data) => {
            sum += data['amount'];
        });
        return sum;
    };

    convertToUserTz = (dateStr: string): string | null => {
        return this.commonService.convertToUserTz(dateStr);
    };

    getPaymentTypes = () => {
        this.chequeService.getPaymentTypes().subscribe({
            next: (res: { name: string; id: string }[]) => {
                if (res) {
                    this.paymentTypes = res;
                }
            },
        });
    };

    getCheques = () => {
        const qString = `bankId=${this.bankAccount}&batchPrinting=1&chequeStatus=0&sort=organizationUpdatedIndex-desc`;
        this.chequeService.getCheques(qString).subscribe({
            next: (res: { content: ICheque[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.cheques = res.content.map((obj) => {
                        const supplier = this.suppliers.find((item) => item.id === obj.supplierId);
                        obj['supplierName'] = supplier ? `${supplier.name?.first}, ${supplier.name?.last}` : '';
                        obj['expenseAccountNumber'] = this.expenseAccounts.find((item) => item.id === obj.expenseAccount)?.account || '';
                        obj['paymentTypeName'] = this.paymentTypes.find((item) => item.id === obj.paymentType)?.name || '';
                        obj['supplierCompanyName'] = obj.supplier?.companyName || '';
                        return obj;
                    });

                    ((this.gridInstance as GridComponent).columns[4] as ColumnModel).customAttributes = { class: 'currency-column' };
                    (((this.gridInstance as GridComponent).aggregates[0].columns || [])[0] as ColumnModel).customAttributes = {
                        class: 'currency-column',
                    };
                }
            },
        });
    };

    getExpenseAccounts = () => {
        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                this.expenseAccounts = res;

                if (this.formType === EBatchPrintingType.CREATE_BATCH || this.bankAccount !== this.initialBatchData?.bankId) {
                    this.getCheques();
                }
            },
        });
    };

    getBankAccounts = () => {
        this.bankAccountService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = res;

                this.fetchBatchData();
            },
        });
    };

    fetchBatchData = () => {
        if (this.isPreviewBatch()) {
            const batchId: string = this.ref?.data?.batchId || '';

            if (batchId) {
                this.getBatchDetailsAndPrint(batchId);
            }
        }
    };

    getSuppliers = () => {
        this.supplierService.getSuppliers('').subscribe({
            next: (res: { content: ISupplier[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    this.suppliers = res.content;
                }
            },
        });
    };

    startBatchPrinting = () => {
        if (this.bankAccount) {
            if (this.isPreviewBatch()) {
                const dialogRef = this.dialog.open(ConfirmationComponent, {
                    minWidth: '35vw',
                    maxWidth: '100%',
                    data: {
                        title: 'Confirm',
                        msg: `Submitting this batch will regenerate the cheques starting with cheque #${
                            this.bankDetailsForm.get('chequeNumber')?.value
                        }. Do you want to continue?`,
                    },
                });
                dialogRef.afterClosed$.subscribe((result) => {
                    if (result) {
                        this.createNewBatch();
                    }
                });
            } else {
                this.createNewBatch();
            }
        }
    };

    createNewBatch = () => {
        const selection = this.gridInstance.getSelectedRecords() as unknown as IBatchTransationCheque[];
        if (selection.length > 0) {
            const payload: IBatchPrintingPayload = {
                name: this.bankDetailsForm.get('name')?.value,
                bankId: this.bankAccount,
                transactions: selection.map((obj) => {
                    return {
                        id: !this.isPreviewBatch() ? obj.id : obj?.transactionId || '',
                    };
                }),
            };

            const bankAccountModified = this.bankAccount !== this.initialBatchData?.bankId;
            const currentChequeNumber = this.bankDetailsForm?.get('chequeNumber')?.value;
            const checkNumberModified = currentChequeNumber != this.chequeSequence;

            if (checkNumberModified) {
                // send cheque number only if cheque is modified
                payload['chequeNumber'] = currentChequeNumber;
            }

            // changing bank account number will create new batch
            const request$ =
                (this.isPreviewBatch() && bankAccountModified) || !this.isPreviewBatch()
                    ? this.batchPrintService.CreateBatchPrinting(payload)
                    : this.batchPrintService.updateBatchPrinting(payload, this.ref?.data?.batchId || '');

            request$.subscribe({
                next: (res) => {
                    this.batchTransactionID = res?.data || '';
                    this.ref.close({ crudOperation: true, batchTransactionID: this.batchTransactionID });
                },
            });
        }
    };

    public rowSelectionChanged(event: RowSelectEventArgs) {
        const selection = this.gridInstance.getSelectedRecords();
        this.gridInstance.aggregateModule.refresh(selection);

        if (event?.isInteracted) {
            if (this.isPreviewBatch()) {
                this.isBatchModified = !this.checkBatchStatus();
            }
        }
    }

    public rowDataBound(args: RowDataBoundEventArgs): void {
        const rowData = args.data as ICheque;

        args.isSelectable =
            this.formType === EBatchPrintingType.CREATE_BATCH || this.bankAccount !== this.initialBatchData?.bankId
                ? !(rowData?.chequeNumber && rowData?.chequeId)
                : !!rowData?.chequeNumber;
    }

    handleChequeNumber = (event: ChangeEventArgs) => {
        const [firstCheque] = this.initialBatchData?.cheques || [];
        this.bankDetailsForm.patchValue({
            chequeNumber: event?.value,
        });

        if (event?.isInteracted && this.isPreviewBatch() && firstCheque?.chequeNumber != event?.value) {
            this.isBatchModified = true; // changing cheque number will update the transactions in the batch
        } else if (this.checkBatchStatus()) {
            this.isBatchModified = false;
        }
    };

    handleNameChange = (event: ChangeEventArgs) => {
        if (event?.isInteracted && this.isPreviewBatch() && this.initialBatchData?.name !== event?.value) {
            this.isBatchModified = true;
        } else if (this.checkBatchStatus()) {
            this.isBatchModified = false;
        }
    };

    // To check the status of the batch form
    checkBatchStatus = () => {
        const checkStatus = this.chequeSequence == this.bankDetailsForm.get('chequeNumber')?.value;
        const bankAccountStatus = this.bankAccount === this.initialBatchData?.bankId;
        const selection = this.gridInstance.getSelectedRecords() as unknown as IBatchTransationCheque[];
        const selectionStatus = this.initialBatchData?.cheques.every((obj) => selection.some((item) => item.id === obj.id));
        const nameStatus = this.bankDetailsForm.get('name')?.value === this.initialBatchData?.name;
        return checkStatus && bankAccountStatus && this.isPreviewBatch() && selectionStatus && nameStatus;
    };

    changeBankAccount = (event: ChangedEventArgs) => {
        if (event.isInteracted) {
            if (this.initialBatchData?.bankId !== event?.value && this.isPreviewBatch()) {
                // changing bank account will create a new batch
                this.isBatchModified = true;
            } else {
                if (this.checkBatchStatus()) {
                    this.isBatchModified = false;
                }

                if (this.initialBatchData?.bankId === event?.value) {
                    this.fetchBatchData();
                }
            }

            this.bankAccount = event?.value || '';

            if (event?.value) {
                this.getExpenseAccounts();
                this.getBankAccountByID(event?.value);
            } else {
                this.cheques = [];
            }
        }
    };

    getBankAccountByID = (id: string, skipChequeNumber: boolean = false) => {
        this.bankAccountService.getBankAccountByID(id).subscribe({
            next: (res: IBankAccounts) => {
                if (res && 'address' in res && res.address) {
                    this.bankAccountAddress = res.address;
                }

                if (!skipChequeNumber) {
                    this.chequeSequence = +(res.chequeSequence || '');
                    this.bankDetailsForm.patchValue({
                        chequeNumber: this.chequeSequence,
                    });
                }
            },
        });
    };

    getFormattedBatchName = (): string => {
        const today = new Date();
        const year = today.getFullYear();
        const monthNames = ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'];
        const month = monthNames[today.getMonth()];
        const day = String(today.getDate()).padStart(2, '0');
        const hours = String(today.getHours()).padStart(2, '0');
        const minutes = String(today.getMinutes()).padStart(2, '0');
        return `BATCH_REQUEST_${month}_${day}_${year}_${hours}_${minutes}`;
    };

    handlePrintCheque = (event: MouseEvent, id: string) => {
        event.stopPropagation();

        if (id && this.isPreviewBatch()) {
            this.chequeService.getChequePDF(id).subscribe({
                next: (res: { status: boolean; data: { url: string } }) => {
                    if (res?.data?.url) {
                        this.getPdfFile(res.data?.url);
                    }
                },
            });
        }
    };

    getPdfFile = (fileURL: string) => {
        if (fileURL) {
            this.loaderService.show();
            this.commonService.getPDFFile(fileURL).subscribe((response: Blob) => {
                const reader = new FileReader();
                reader.onload = () => {
                    const arrayBuffer = reader.result as ArrayBuffer;
                    const byteArray = new Uint8Array(arrayBuffer);

                    const blob = new Blob([byteArray], {
                        type: 'application/pdf',
                    });

                    const url = URL.createObjectURL(blob);
                    this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(url);

                    setTimeout(() => {
                        this.printFile();
                        this.loaderService.hide();
                    }, 1000);
                };

                reader.readAsArrayBuffer(response);
            });
        }
    };

    printFile = () => {
        if (this.pdfViewer) {
            const iframe = this.pdfViewer.nativeElement;
            iframe.contentWindow.print();
        }
    };
}
