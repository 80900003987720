import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, inject } from '@angular/core';
import { AbstractControl, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DateTime } from 'luxon';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { DialogRef } from '@ngneat/dialog';
import { ChangeEventArgs } from '@syncfusion/ej2-angular-dropdowns';
import { ToWords } from 'to-words';
import {
    EditService,
    ToolbarService,
    PageService,
    PageSettingsModel,
    GridComponent,
    ToolbarItems,
    EditSettingsModel,
    SaveEventArgs,
    SortService,
    ColumnModel,
} from '@syncfusion/ej2-angular-grids';

import {
    EToasterStatus,
    IBankAccounts,
    ICheque,
    IChequeError,
    IChequeExpenses,
    IChequePayload,
    IECHQAddress,
    IExpenseAccounts,
    IMeta,
    ISupplier,
    ITax,
} from '@core/models';
import {
    BankAccountService,
    ExpenseAccountService,
    TaxSetupService,
    SupplierService,
    ChequeService,
    ToasterService,
    UserService,
} from '@core/services';
import { Constant } from '@core/constants';

interface Data {
    isEdit: boolean;
    transactionID?: string;
}

@Component({
    selector: 'app-create-cheque',
    styleUrls: ['./create-cheque.component.scss'],
    templateUrl: './create-cheque.component.html',
    providers: [ToolbarService, EditService, PageService, SortService],
})
export class CreateChequeComponent implements OnInit, AfterViewInit {
    public supplierId: string = '';
    public supplierNames: ISupplier[] = [];
    public bankAccount: string = '';
    public bankAccounts: IBankAccounts[] = [];
    public expenseAccounts: IExpenseAccounts[] = [];
    public taxCodes: ITax[] = [];
    public pdfUrl: SafeResourceUrl = '';
    public transaction: ICheque | null = null;
    @ViewChild('pdfViewer') pdfViewer!: ElementRef;
    public ref: DialogRef<Data, { file: string } | { batchPrinting: boolean } | null> = inject(DialogRef);
    public bankAccountAddress: IECHQAddress | null = null;
    public supplierAddress: IECHQAddress | null = null;
    public amountInWords: string = '';
    public pageSettings: PageSettingsModel = { pageCount: 5, pageSize: 10 };
    public data: IChequeExpenses[] = [];
    public toolbar: ToolbarItems[] = ['Add', 'Edit', 'Delete', 'Update', 'Cancel'];
    public isEditingInProgress: boolean = false;
    public editSettings: EditSettingsModel = {
        allowEditing: true,
        allowAdding: true,
        allowDeleting: true,
        mode: 'Normal',
        newRowPosition: 'Bottom',
    };
    public toWords: ToWords = new ToWords({
        localeCode: 'en-IN',
        converterOptions: {
            currency: true,
            ignoreDecimal: false,
            ignoreZeroCurrency: false,
            doNotAddOnly: false,
            currencyOptions: {
                name: 'Dollar',
                plural: 'Dollars',
                symbol: '$',
                fractionalUnit: {
                    name: 'Cent',
                    plural: 'Cents',
                    symbol: '',
                },
            },
        },
    });
    public fields: { text: string; value: string } = {
        text: 'name',
        value: 'id',
    };
    public taxFields: { text: string; value: string; tax: string } = { text: 'name', value: 'code', tax: 'tax' };
    public supplierFields: { text: string; value: string } = {
        text: 'nameOnCheque',
        value: 'id',
    };
    public checkForm: FormGroup = new FormGroup({
        supplierId: new FormControl<string>('', [Validators.required]),
        bankAccount: new FormControl<string>('', [Validators.required]),
        paymentType: new FormControl<string>('', [Validators.required]),
        chequeNumber: new FormControl<number>({ value: 0, disabled: true }, [Validators.required]),
        customTransactionNumber: new FormControl<string>(''),
        supplierDetails: new FormControl<string>('', []),
        transactionDate: new FormControl<string>('', [Validators.required]),
        amount: new FormControl<number | null>(null, [Validators.required]),
        memo: new FormControl<string>('', [Validators.maxLength(255)]),
        batchPrinting: new FormControl<boolean>(false, []),
    });
    public expenseAccountForm!: FormGroup;
    @ViewChild('inlineChequeGrid', { static: true })
    public grid!: GridComponent;
    public paymentTypes: { name: string; id: string }[] = [];
    public selectedTax: ITax | null = null;
    public selectedPaymentType: { name: string; id: string } | null = null;

    constructor(
        private fb: FormBuilder,
        private bankService: BankAccountService,
        private expenseAccountService: ExpenseAccountService,
        private taxService: TaxSetupService,
        private chequeService: ChequeService,
        private supplierService: SupplierService,
        private toasterService: ToasterService,
        private userService: UserService,
        private constant: Constant,
        private sanitizer: DomSanitizer
    ) {
        this.checkForm = this.fb.group({
            supplierId: ['', [Validators.required, Validators.maxLength(255)]],
            bankAccount: ['', [Validators.required, Validators.maxLength(255)]],
            paymentType: ['Cheque', [Validators.required]],
            chequeNumber: [{ value: null, disabled: true }, [Validators.required]],
            customTransactionNumber: [''],
            supplierDetails: ['', []],
            transactionDate: ['', [Validators.required]],
            amount: [null, [Validators.required]],
            memo: ['', [Validators.maxLength(255)]],
            batchPrinting: [false, []],
        });
        this.checkForm.get('amount')?.valueChanges.subscribe((value) => {
            this.handleAmountChange(value);
        });
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl('');
    }

    ngOnInit(): void {
        this.getPaymentTypes();
    }

    ngAfterViewInit(): void {
        [3, 4, 5].forEach((item) => {
            ((this.grid as GridComponent).columns[item] as ColumnModel).customAttributes = {
                class: 'currency-column',
            };
        });
        [1, 2, 3].forEach((item) => {
            (((this.grid as GridComponent).aggregates[0].columns || [])[item] as ColumnModel).customAttributes = {
                class: 'currency-column',
            };
        });
    }

    get checkFormF(): { [key: string]: AbstractControl } {
        return this.checkForm.controls;
    }

    get expenseFormF(): { [key: string]: AbstractControl } {
        return this.expenseAccountForm.controls;
    }

    get title() {
        return this.ref.data.isEdit ? 'Edit Cheque / ePayments' : 'Create Cheque / ePayments';
    }

    get isEdit() {
        return this.ref.data?.isEdit;
    }

    get transactionID() {
        return this.ref.data?.transactionID;
    }

    getPaymentTypes = () => {
        this.chequeService.getPaymentTypes().subscribe({
            next: (res: { name: string; id: string }[]) => {
                if (res) {
                    this.paymentTypes = res;
                    const chequePayment: { name: string; id: string } | null =
                        this.paymentTypes.find((item) => item.name === 'Cheque') || null;
                    this.selectedPaymentType = chequePayment;
                    this.getSupplier();

                    if (chequePayment) {
                        this.checkForm.patchValue({ paymentType: chequePayment?.id });
                    }
                }
            },
        });
    };

    handlePaymentTypeChange = (event: ChangeEventArgs) => {
        this.selectedPaymentType = event.itemData as unknown as { name: string; id: string };
        if (
            (this.selectedPaymentType && this.selectedPaymentType.name !== 'Cheque') ||
            (this.selectedPaymentType.name === 'Cheque' && this.checkForm.get('batchPrinting')?.value)
        ) {
            this.checkForm.get('chequeNumber')?.removeValidators([Validators.required]);
        } else {
            this.checkForm.get('chequeNumber')?.setValidators([Validators.required]);
        }

        this.checkForm.get('chequeNumber')?.updateValueAndValidity();
    };

    getBankAccounts() {
        this.bankService.getBankAccounts().subscribe({
            next: (res: IBankAccounts[]) => {
                this.bankAccounts = res;
                this.getExpenseAccounts();
            },
        });
    }

    getExpenseAccounts() {
        this.expenseAccountService.getExpenseAccounts().subscribe({
            next: (res: IExpenseAccounts[]) => {
                this.expenseAccounts = res;
                this.getTaxCode();
            },
        });
    }

    getTaxCode() {
        this.taxService.getTaxes().subscribe({
            next: (res: ITax[]) => {
                this.taxCodes = res;

                if (this.isEdit && this.transactionID) {
                    this.getTransactionByID(this.transactionID);
                }
            },
        });
    }

    getSupplier() {
        this.supplierService.getSuppliers('').subscribe({
            next: (res: { content: ISupplier[]; meta: IMeta }) => {
                if (res && 'content' in res && res.content) {
                    if (this.isEdit && this.transactionID) {
                        this.supplierNames = res.content;
                    } else {
                        this.supplierNames = res.content.filter((obj) => obj.active);
                    }
                    this.getBankAccounts();
                }
            },
        });
    }

    getSupplierByID = (id: string, fromOnLoad = false) => {
        this.supplierService.getSupplierInfo(id).subscribe({
            next: (res: ISupplier) => {
                if (!fromOnLoad) {
                    this.checkForm.patchValue({
                        supplierDetails: res.nameOnCheque,
                        expenseAccount: res.defaultExpenseAccount,
                        taxCode: res.defaultTaxCode,
                    });
                }

                if (res && 'address' in res && res.address) {
                    this.supplierAddress = res.address;
                } else {
                    this.supplierAddress = null;
                }
            },
        });
    };

    getBankAccountByID = (id: string, fromOnLoad = false) => {
        this.bankService.getBankAccountByID(id).subscribe({
            next: (res: IBankAccounts) => {
                if (res && 'address' in res && res.address) {
                    this.bankAccountAddress = res.address;
                } else {
                    this.bankAccountAddress = null;
                }

                if (!fromOnLoad) {
                    this.checkForm.patchValue({
                        chequeNumber: res.chequeSequence,
                    });

                    if (!this.isEdit) {
                        this.checkForm.get('chequeNumber')?.disable();
                    }
                }
            },
        });
    };

    getTransactionByID = (id: string) => {
        this.chequeService.getChequeByID(id).subscribe({
            next: (res: ICheque) => {
                this.transaction = res;
                const { paymentType } = res;
                this.selectedPaymentType = this.paymentTypes.find((item) => item.id === paymentType) || null;

                this.bindTransaction(res);
                this.getBankAccountByID(res.bankAccount, true);
                this.getSupplierByID(res.supplierId, true);
                this.supplierNames = this.supplierNames.filter((obj) => obj.active || obj.id === res.supplierId);
            },
        });
    };

    bindTransaction = (transaction: ICheque) => {
        if (transaction) {
            this.checkForm.patchValue({
                supplierId: transaction.supplierId || '',
                bankAccount: transaction.bankAccount || '',
                paymentType: transaction.paymentType || '',
                supplierDetails: transaction.supplierDetails || '',
                transactionDate: transaction.transactionDate || '',
                amount: transaction.amount || '',
                memo: transaction.memo || '',
                batchPrinting: transaction.batchPrinting || false,
                chequeNumber: transaction.chequeNumber || null,
                customTransactionNumber: transaction.customTransactionNumber || '',
            });

            this.data = transaction.amountBreakdown.map((item, index: number) => {
                const { expenseAccountNumber, taxCodeValue } = this.formatAccountAndTax(item);
                item['expenseAccountNumber'] = expenseAccountNumber;
                item['taxCodeValue'] = taxCodeValue;
                return item;
            });

            this.checkForm.get('chequeNumber')?.enable();

            if ((transaction.batchPrinting && !transaction.chequeNumber) || this.selectedPaymentType?.name !== 'Cheque') {
                this.checkForm.get('chequeNumber')?.removeValidators([Validators.required]);
                this.checkForm.get('chequeNumber')?.updateValueAndValidity();
            }

            if (transaction.chequeNumber) {
                this.checkForm.get('paymentType')?.disable();
                this.checkForm.get('batchPrinting')?.disable();
            }
        }
    };

    changeSupplierName = (supplierName: string, event: ChangeEventArgs) => {
        this.supplierId = event.value ? `${event.value}` : '';

        if (this.supplierId && event.isInteracted) {
            this.getSupplierByID(this.supplierId);
        } else if (!this.supplierId) {
            this.supplierAddress = null;
        }
    };

    changeBankAccount = (bankAccount: string, event: ChangeEventArgs) => {
        this.bankAccount = event.value ? `${event.value}` : '';

        if (event.isInteracted && this.bankAccount) {
            this.getBankAccountByID(this.bankAccount);
        } else if (!this.bankAccount) {
            this.bankAccountAddress = null;
        }
    };

    changeExpenseAccount = (event: ChangeEventArgs) => {
        const selectedTaxCode: string = this.expenseAccounts.find((obj) => obj.id === event.value)?.taxCode || '';
        this.expenseAccountForm.patchValue({
            taxCode: selectedTaxCode || null,
        });
        this.computeNetAmount();
    };

    changeTaxCode = (event: ChangeEventArgs) => {
        this.selectedTax = event.itemData as unknown as ITax;
        this.computeNetAmount();
    };

    handleExpenseAmountChange = (event: ChangeEventArgs) => {
        this.computeNetAmount();
    };

    handleTaxAmountChange = (event: ChangeEventArgs) => {
        this.expenseAccountForm.patchValue({
            taxAmount: event.value,
        });
        this.computeNetAmount(true);
    };

    computeNetAmount = (isTaxChange: boolean = false) => {
        const formValue = this.expenseAccountForm.getRawValue();
        const {
            expenseAccount,
            taxCode,
            amount,
            taxAmount: taxAmountFromForm,
        }: { expenseAccount: string; taxCode: string; amount: number; taxAmount: number } = formValue;
        const selectedTax = this.selectedTax ? this.selectedTax : this.taxCodes.find((obj) => obj.code === taxCode);

        if (expenseAccount && amount && selectedTax) {
            const { tax }: { tax: number } = selectedTax;

            let taxAmount: number = 0;
            let netAmount: number = 0;
            const taxRate: number = tax / 100;
            const taxRateForCalculation: number = taxRate + 1;

            if (isTaxChange) {
                if (taxAmountFromForm !== undefined && taxAmountFromForm !== null) {
                    netAmount = Number((Math.round((amount - taxAmountFromForm) * 100) / 100).toFixed(2));
                } else {
                    netAmount = Number((Math.round((amount / taxRateForCalculation) * 100) / 100).toFixed(2));
                }
            } else {
                netAmount = Number((Math.round((amount / taxRateForCalculation) * 100) / 100).toFixed(2));
            }
            taxAmount = Number((Math.round((amount - netAmount) * 100) / 100).toFixed(2));

            this.expenseAccountForm.patchValue({
                netAmount: netAmount,
                taxAmount: taxAmount,
            });
        }
    };

    cancel = () => {
        this.checkForm.reset();
        this.ref.close(null);
    };

    createFormGroup(data: IChequeExpenses): FormGroup {
        return new FormGroup({
            expenseAccount: new FormControl(data.expenseAccount, Validators.required),
            taxCode: new FormControl(data.taxCode, Validators.required),
            amount: new FormControl(data.amount, Validators.required),
            netAmount: new FormControl({ value: data.netAmount, disabled: true }),
            taxAmount: new FormControl(data.taxAmount, [Validators.required]),
            description: new FormControl(data.description, [Validators.maxLength(255)]),
        });
    }

    createCheque = () => {
        if (this.checkForm.valid) {
            const formValue = this.checkForm.getRawValue();
            const orgID: string = this.userService.organizationId;

            const payload: IChequePayload = {
                ...formValue,
                transactionDate: this.convertJsDateToUtc(formValue.transactionDate),
                organization: orgID,
                batchPrinting: formValue?.batchPrinting || false,
                description: '',
                amountBreakdown: this.formatAmountBreakdown(),
                chequeNumber: formValue.chequeNumber ? +formValue.chequeNumber : null,
            };

            this.isEdit && delete payload.organization;
            this.isEdit && (payload.id = this.transactionID);

            if ((!!formValue?.batchPrinting && !this.isEdit) || !this.selectedPaymentType || this.selectedPaymentType?.name !== 'Cheque') {
                delete payload.chequeNumber;
            }

            const transactionReq$ =
                this.isEdit && this.transactionID
                    ? this.chequeService.updateTransaction(this.transactionID, payload)
                    : this.chequeService.CreateCheque(payload);

            transactionReq$.subscribe({
                next: (
                    res:
                        | {
                              data: { id: string; file: string; preview: string };
                              status: boolean;
                              message: string;
                          }
                        | ICheque
                ) => {
                    this.showToast(
                        EToasterStatus.SUCCESS,
                        this.isEdit ? this.constant.CHEQUE_MSG.UPDATE_SUCCESS : this.constant.CHEQUE_MSG.SUCCESS
                    );
                    this.checkForm.reset();

                    if (
                        (this.isEdit && payload.batchPrinting) ||
                        payload.batchPrinting ||
                        (payload.paymentType && 'paymentType' in payload && this.selectedPaymentType?.name !== 'Cheque')
                    ) {
                        this.ref.close({
                            batchPrinting:
                                (this.isEdit && payload.batchPrinting) ||
                                payload.batchPrinting ||
                                ('paymentType' in payload && !!payload.paymentType && this.selectedPaymentType?.name !== 'Cheque'),
                        });
                    } else {
                        if (res && 'data' in res && res.data && 'file' in res.data && res.data.file) {
                            this.ref.close({ file: res.data.file });
                        }
                    }
                },
                error: (e) => {
                    const errorMsg: string = this.isEdit ? this.constant.CHEQUE_MSG.UPDATE_FAILURE : this.constant.CHEQUE_MSG.FAILURE;

                    if (e) {
                        const err: IChequeError = 'error' in e && e.error ? e.error : { error: '' };
                        const { error } = err ? err : { error: { message: '' } };
                        const { message } = error ? error : { message: [] };
                        const [msg] = message || errorMsg;
                        this.showToast(EToasterStatus.DANGER, msg);
                    } else {
                        this.showToast(EToasterStatus.DANGER, errorMsg);
                    }
                },
            });
        }
    };

    handleBatchPrintChange = (event: { checked: boolean }) => {
        if (event.checked) {
            this.checkForm.patchValue({
                chequeNumber: null,
            });
            this.checkForm.get('chequeNumber')?.removeValidators([Validators.required]);
        } else if (this.checkForm.get('bankAccount')?.value) {
            this.getBankAccountByID(this.checkForm.get('bankAccount')?.value);
            this.checkForm.get('chequeNumber')?.setValidators([Validators.required]);
        }
        this.checkForm.get('chequeNumber')?.updateValueAndValidity();
    };

    actionBegin(args: SaveEventArgs): void {
        if (args.requestType === 'beginEdit' || args.requestType === 'add') {
            this.expenseAccountForm = this.createFormGroup(args.rowData as unknown as IChequeExpenses);
            this.isEditingInProgress = true;

            if (args.requestType === 'add') {
                if (this.data.length === 10) {
                    this.showToast(EToasterStatus.WARNING, 'Maximum 10 expense details are allowed.');
                    args.cancel = true;
                    this.isEditingInProgress = false;
                    return;
                }
            }
        } else if (args.requestType === 'cancel') {
            this.isEditingInProgress = false;
        }

        if (args.requestType === 'save') {
            const { id } = args.rowData as unknown as IChequeExpenses;

            if (this.expenseAccountForm.valid) {
                const formValue: IChequeExpenses = this.expenseAccountForm.getRawValue();
                const { amount, taxAmount } = formValue;

                if (taxAmount > amount) {
                    this.showToast(EToasterStatus.DANGER, 'Tax amount cannot be greater than the gross amount');
                    args.cancel = true;
                    return;
                }

                const { expenseAccountNumber, taxCodeValue } = this.formatAccountAndTax(formValue);
                args.data = {
                    ...formValue,
                    expenseAccountNumber,
                    taxCodeValue,
                    id: id ? id : new Date().getTime().toString(),
                    isNew: true,
                };

                if (args.action === 'edit') {
                    this.data = this.data.map((item) => (item.id === id ? (args.data as IChequeExpenses) : item));
                }
            } else {
                args.cancel = true;
            }
        }
    }

    actionComplete = (args: SaveEventArgs) => {
        if (args.requestType === 'save' || args.requestType === 'delete') {
            this.isEditingInProgress = false;
        }

        if (args.requestType === 'save') {
            const gridData: IChequeExpenses[] = this.grid.dataSource as unknown as IChequeExpenses[];
            const total: number = gridData.reduce((sum: number, item: IChequeExpenses) => sum + item.amount, 0);
            this.checkForm.patchValue({ amount: total });
        }

        if (args.requestType === 'delete') {
            const gridData: IChequeExpenses[] = this.grid.dataSource as unknown as IChequeExpenses[];

            const total: number = gridData.reduce((sum: number, item: IChequeExpenses) => sum + item.amount, 0);
            this.checkForm.patchValue({ amount: total ? total : null });
        }
    };

    formatAccountAndTax = (formValue: IChequeExpenses): { expenseAccountNumber: string; taxCodeValue: string } => {
        const { expenseAccount, taxCode } = formValue;
        const expenseAccountNumber: string = this.expenseAccounts.find((item) => item.id === expenseAccount)?.name || '';
        const taxCodeValue: string = this.taxCodes.find((item) => item.code === taxCode)?.name || '';
        return { expenseAccountNumber, taxCodeValue };
    };

    formatAmountBreakdown = (): IChequeExpenses[] => {
        let expenses: IChequeExpenses[] = (this.grid.dataSource as unknown as IChequeExpenses[]) || [];
        expenses = expenses.map((item: IChequeExpenses) => {
            if (item.isNew) {
                delete item.id;
                delete item.isNew;
            }
            return { ...item, description: item?.description || '' };
        });
        return expenses;
    };

    handleAmountChange = (amount: number) => {
        this.amountInWords = amount ? this.toWords.convert(amount) : '';
    };

    public focusIn(target: HTMLElement | any): void {
        (target as any).parentElement.classList.add('e-input-focus');
    }

    public focusOut(target: HTMLElement | any): void {
        (target as any).parentElement.classList.remove('e-input-focus');
    }

    convertJsDateToUtc = (date: Date | string): string => {
        if (typeof date === 'string') {
            // If the input is already a string, return it as is
            return date;
        } else if (date instanceof Date && !isNaN(date.getTime())) {
            // If the input is a valid Date object, convert it to UTC string
            const formattedDate: string = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd');
            return formattedDate ? `${formattedDate}T00:00:00.000Z` : '';
        }
        return '';
    };

    showToast(title: EToasterStatus, message: string) {
        this.toasterService.showToast(title, message);
    }

    formatTaxDisplay = (data: ITax) => {
        return `${data.code} (${data.name} - ${data.tax}%)`;
    };
}
