export interface ICheque {
    id: string;
    supplierName: string;
    bankAccount: string;
    supplierDetails: string;
    transactionDate: string;
    chequeNumber?: number;
    expenseAccount: string;
    description: string;
    amount: number;
    taxCode: string;
    memo: string;
    batchPrinting: boolean;
    supplierId: string;
    previewPdf: string;
    created: string;
    chequeStatus: boolean;
    createdBy: string;
    deleted: boolean;
    organization: string;
    chequeId: string;
    latestPdf: string;
    modifiedBy: string;
    bankAccountNumber?: string;
    bankAccountName?: string;
    expenseAccountNumber?: string;
    amountBreakdown: IChequeExpenses[];
    amountBeforeTaxes: number;
    taxAmount: number;
    [key: string]: any;
    month?: string;
    paymentTypeName?: string;
    paymentType: string;
    customTransactionNumber: string;
    supplierCompanyName?: string;
    supplier?: {
        name: string;
        companyName: string;
        defaultTaxCode: string;
    };
}

export interface IChequePayload {
    id?: string;
    bankAccount: string;
    supplierId: string;
    description: string;
    amount: number;
    chequeDate: string;
    chequeNumber?: string;
    memo: string;
    batchPrinting: boolean;
    organization?: string;
    paymentType: string;
    amountBreakdown: IChequeExpenses[];
}

export interface IChequeError {
    error: {
        statusCode: number;
        message: string[];
        error: string;
    };
}

export interface IBatchPrintingPayload {
    name: string;
    bankId: string;
    transactions: { id: string; chequeNumber?: number }[];
    chequeNumber?: number;
}

export interface IBatchTransation {
    bankId: string;
    chequeUrl?: string;
    createdAt: string;
    id: string;
    name: string;
    organization: string;
    status: string;
    updatedAt: string;
    bankName?: string;
}

export interface IBatchTransationDetail {
    bankId: string;
    chequeUrl?: string;
    createdAt: string;
    id: string;
    name: string;
    organization: string;
    status: string;
    updatedAt: string;
    file?: string;
    isModified?: boolean;
    cheques: IBatchTransationCheque[];
}

export interface IBatchTransationCheque {
    organization: string;
    updatedAt: string;
    status: string;
    createdAt: string;
    transactionDetails: IBatchTransactionDetails;
    errorMessage: string;
    id: string;
    transactionId: string;
    batchId: string;
    chequeNumber: number;
    file: string;
    isModified: boolean;
    preview: string;
}

export interface IBatchTransactionDetails {
    bankAccount: string;
    amount: number;
    supplierId: string;
    memo: string;
    description: string;
    id: string;
    transactionDate: string;
    amountBreakdown: IChequeExpenses[];
    paymentType: string;
}

export interface IChequeExpenses {
    id?: string;
    expenseAccount: string;
    taxCode: string;
    amount: number;
    taxAmount: number;
    netAmount: number;
    description: string;
    expenseAccountNumber?: string;
    taxCodeValue?: string;
    isNew?: boolean;
}

export enum EBatchPrintingType {
    CREATE_BATCH = 'create',
    PREVIEW_BATCH = 'preview',
}

export interface IBatchPrintingDlgData {
    type: EBatchPrintingType;
    batchId?: string;
}
